import './App.css';
import { Route, Routes } from 'react-router-dom';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import HomePage from './HomePage/HomePage';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { createContext, useState } from 'react';
import AboutUs from './AboutUs/AboutUs';
import ContactUs from './ContactUs/ContactUs';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import Disclaimer from './Disclaimer/Disclaimer';
import Career from './Career/Career';
import FeedbackForm from './FeedbackForm/FeedbackForm';
import CareerForm from './CareerForm/CareerForm';
import ServicesOverview from './ServicesOverview/ServicesOverview';
import ProductsOverview from './ProductsOverview/ProductsOverview';
import IFATransact from './ProductsOverview/AllProducts/IFATransact/IFATransact';
import WealthWraps from './ProductsOverview/AllProducts/WealthWraps/WealthWraps';
import APIDataFeed from './ProductsOverview/AllProducts/APIDataFeed/APIDataFeed';
import CMOTSeKYC from './ProductsOverview/AllProducts/CMOTSeKYC/CMOTSeKYC';
import CmotsIPOOnline from "./ProductsOverview/AllProducts/CmotsIPOOnline/CmotsIPOOnline"
import CmotsLMS from "./ProductsOverview/AllProducts/CmotsLMS/CmotsLMS"
import SalesCRM from "./ProductsOverview/AllProducts/SalesCRM/SalesCRM"
import VirtualPortfolioTracker from "./ProductsOverview/AllProducts/VirtualPortfolioTracker/VirtualPortfolioTracker"
import IssueTrackingSystem from "./ProductsOverview/AllProducts/IssueTrackingSystem/IssueTrackingSystem"
import WebsiteDesignDevelopment from './ServicesOverview/AllServices/WebsiteDesignDevelopment/WebsiteDesignDevelopment';
import WebsiteHosting from './ServicesOverview/AllServices/WebsiteHosting/WebsiteHosting';
import MobileDevelopment from './ServicesOverview/AllServices/MobileDevelopment/MobileDevelopment';
import CustomizedSoftwareDevelopment from './ServicesOverview/AllServices/CustomizedSoftwareDevelopment/CustomizedSoftwareDevelopment';
import DataProvider from './ServicesOverview/AllServices/DataProvider/DataProvider';
import ResourceStaffing from './ServicesOverview/AllServices/ResourceStaffing/ResourceStaffing';
import MobileMenu from "./MobileMenu/MobileMenu";
import ErrorPage from "./ErrorPage/ErrorPage";

let apiURL;
const SetLocation = window.location.origin;

if (SetLocation == 'http://cmweb:809') {
  apiURL = "http://cmweb:810/api";
}
else if (SetLocation == 'http://localhost:3006') {
  apiURL = "http://cmweb:810/api";
}
else {
  apiURL = "https://cmots2023apis.cmots.com/api";
}
const getApiUrl = createContext(apiURL);
function App() {

  const [dimensions, setDimensions] = useState(0);


  return (
    <div className="App">
      <getApiUrl.Provider value={apiURL}>
        <Header setDimensions={setDimensions} />
        <Routes>
        <Route path="*" element={<ErrorPage height={dimensions} />} exact />
          <Route path="/" element={<HomePage height={dimensions} />} exact />
          <Route path='/about-us' element={<AboutUs height={dimensions} />} />
          <Route path='/contact-us' element={<ContactUs height={dimensions} />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy height={dimensions} />} />
          <Route path='/disclaimer' element={<Disclaimer height={dimensions} />} />
          <Route path='/feedback' element={<FeedbackForm height={dimensions} />} />
          <Route path='/career-form' element={<CareerForm height={dimensions} />} />
          <Route path='/careers' element={<Career height={dimensions} />} />
          <Route path='/services' element={<ServicesOverview height={dimensions} />} />
          <Route path='/products' element={<ProductsOverview height={dimensions} />} />
          <Route path='/products/ifa-transact' element={<IFATransact height={dimensions} />} />
          <Route path='/products/wealthwraps' element={<WealthWraps height={dimensions} />} />
          <Route path='/products/api-datafeed' element={<APIDataFeed height={dimensions} />} />
          <Route path='/products/cmots-ekyc' element={<CMOTSeKYC height={dimensions} />} />
          <Route path='/products/ipo-online' element={<CmotsIPOOnline height={dimensions} />} />
          <Route path='/products/cmots-lms' element={<CmotsLMS height={dimensions} />} />
          <Route path='/products/sales-crm' element={<SalesCRM height={dimensions} />} />
          <Route path='/products/virtual-portfolio-tracker' element={<VirtualPortfolioTracker height={dimensions} />} />
          <Route path='/products/issue-tracking' element={<IssueTrackingSystem height={dimensions} />} />
          <Route path='/services/website-design-and-development' element={<WebsiteDesignDevelopment height={dimensions} />} />
          <Route path='/services/website-hosting' element={<WebsiteHosting height={dimensions} />} />
          <Route path='/services/mobile-development' element={<MobileDevelopment height={dimensions} />} />
          <Route path='/services/customized-software-development' element={<CustomizedSoftwareDevelopment height={dimensions} />} />
          <Route path='/services/market-data-provider' element={<DataProvider height={dimensions} />} />
          <Route path='/services/resource-staffing' element={<ResourceStaffing height={dimensions} />} /> </Routes>
        <MobileMenu />
        <Footer />
      </getApiUrl.Provider>
    </div>
  );
}

export default App;
export { apiURL, getApiUrl };
