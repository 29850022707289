import {React, useEffect, useState, useContext} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './CompanyAddress.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { getApiUrl } from "../../App";

function CompanyAddress() {
    const[data, getData] =useState("")
    const setApiUrl = useContext(getApiUrl);
    useEffect(() => {
        AOS.init({
        once:'true',
          disable: function() {
          var maxWidth = 992;
          return window.innerWidth < maxWidth;
          }
       });
    } , [])

useEffect(() =>{
    fetch(`${setApiUrl}/location`)
    .then((response) => response.json())
    .then((data) => {
        getData(data.data)
        //console.log(data)
    })

}, [setApiUrl])
    return (
    <>
   <Container className='mb-5'>
    <Row>
        <Col  data-aos="fade-right"   data-aos-duration="1000">
        <div className='main-header no-space-head'>
            <h2 className='text-left'> We're here for you. <br />
Say HELLO!</h2>
            </div>
        </Col>
    </Row>
    <Row  data-aos="fade-up"   data-aos-duration="1000">
        <Col sx={12} className='mt-2'>
        <div className='IndiaHeading'>
            India
        </div>
        <div className='AddresTable'>
            <ul>
            <li> 
                <h2> Mumbai </h2>
                <h6> <span> Registered Office: </span>
                19/20, 'A' Wing, Satyam Shopping Centre, M.G. Road, Ghatkopar(E), Mumbai, 400077, Maharashtra
                </h6>               
                </li>
                <li>
                <h6> <span> Sales Enquiry: </span> <a href="tel: +91-9769005437">+91 9769005437,</a> <a href="tel: +91-9500007439"> 9500007439 </a></h6>
                <h6>  <span> Email: </span> <a href="mailto:info@cmots.com" > info@cmots.com </a> </h6>
                </li>

            {/* {data && data.map(({city, office,address, phone, email}, index) => (
                <li key={index}> 
                <h2> {city} </h2>
                <h6> <span> {office} </span>
                {address}
                </h6>
                <h6> <span> Sales Inquiry: </span> +91 {phone}  </h6>
                <h6>  <span> Email: </span> <a href="mailto:info@cmots.com" > {email} </a> </h6>
                </li>
            ))}  */}

            </ul>
        </div>
        </Col>
    </Row>
   </Container>
    </>
    );
}

export default CompanyAddress;