import {React , useEffect} from "react";
import "./Banner.css";
import { Col, Container, Row } from "react-bootstrap";
import diamondBanner from "../../Images/diamond-banner.png";
import bannerImg2 from "../../Images/banner-img-2.png";
import desktopSlideSmall1 from '../../Images/desktop-slide-small-1.png'
import desktopSlideSmall2 from "../../Images/desktop-slide-small-2.png";
import tabSlide1 from "./../../Images/tab-slide-1.png";
import tabSlide2 from "./../../Images/tab-slide-2.png";
import mobileSlibeBig1 from "./../../Images/mobile-slide-big1.png";
import mobileSlibeBig2 from "./../../Images/mobile-slide-big2.png";
import mobileSlideMiddle1 from "./../../Images/mobile-slide-middle1.png";
import mobileSlideMiddle2 from "./../../Images/mobile-slide-middle2.png";
import mobileSlideSmall1 from "./../../Images/mobile-slide-small1.png";
import mobileSlideSmall2 from "./../../Images/mobile-slide-small2.png";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slider from "react-slick";
// import BannerBg1 from "./../../Images/banner-bg.png";
const Banner = (props) =>{
    //console.log("hello2",props.xyz)
    useEffect(() => {
        AOS.init({
        once:'true',
          disable: function() {
          var maxWidth = 992;
          return window.innerWidth < maxWidth;
          }
       });
    } , [])
    const settings = {
        arrows:false,
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        fade: true,
        cssEase: 'linear',
        pauseOnHover : false
    };
    return(
        <>
            <div className="banner-sc">
                
                <div className="desktop-slide">
                    <Slider {...settings} >
                            <div className="banner-inner"  style={{paddingTop: props.heightPass}}>
                                <div className="banner-one-bg">
                                {/* <img src={BannerBg1} alt="Banner Backgroung" className="img-fluid" /> */}
                                <Container>
                                    <Row>
                                        <Col>
                                            <img src={diamondBanner} alt="banner" className="img-fluid"  usemap="#image-map1"/>
                                            <map name="image-map1">
                                                <area target="_self" alt="" title="" href="/products/api-datafeed" coords="114,260,83,207,144,205,178,260,148,316,83,314,50,261,82,207" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/wealthwraps" coords="218,194,188,143,250,142,282,198,251,250,187,251,156,200,187,142" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/ifa-transact" coords="324,134,294,81,356,81,388,133,356,189,292,187,261,137,293,81" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/cmots-ekyc" coords="217,321,250,264,285,320,253,375,187,373,157,318,187,262,251,264" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/ipo-online" coords="323,255,357,204,387,257,359,312,292,313,261,258,294,204,356,205" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/cmots-lms" coords="430,195,463,140,494,195,464,249,400,249,368,194,400,141,462,141" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/sales-crm" coords="324,379,359,322,391,377,360,433,295,434,262,379,296,323,359,323,358,324" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/virtual-portfolio-tracker" coords="431,317,465,260,496,315,466,372,403,373,369,317,401,259,466,261" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/issue-tracking" coords="536,256,568,199,600,253,571,308,505,310,473,253,504,198,568,200" shape="poly"></area>
                                            </map>
                                            <div className="banner-content">
                                                <h1>Your Partner in Financial <br/> Growth with a Focus on <br/> <span>Quality</span></h1>
                                                <p>We specialize in transforming data into actionable insights, <br/>helping you navigate the complexities of finance with <br/>confidence</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                                </div>
                            </div>
                            
                            <div className="banner-inner"  style={{paddingTop: props.heightPass}}>
                                <div className="banner-two-bg">
                                {/* <img src={BannerBg1} alt="Banner Backgroung" className="img-fluid" /> */}
                                <Container>
                                    <Row>
                                        <Col>
                                            <img src={bannerImg2} alt="banner" className="img-fluid"  usemap="#image-map"/>
                                            
                                            <map name="image-map">
                                                <area target="_self" alt="" title="" href="/services/market-data-provider" coords="430,111,60" shape="circle"></area>
                                                <area target="_self" alt="" title="" href="/services/mobile-development" coords="516,236,60" shape="circle"></area>
                                                <area target="_self" alt="" title="" href="/services/customized-software-development" coords="428,354,59" shape="circle"></area>
                                                <area target="_self" alt="" title="" href="/services/website-design-and-development" coords="225,353,62" shape="circle"></area>
                                                <area target="_self" alt="" title="" href="/services/website-hosting" coords="134,236,63" shape="circle"></area>
                                                <area target="_self" alt="" title="" href="/services/resource-staffing" coords="224,110,62" shape="circle"></area>
                                            </map>
                                            <div className="banner-content">
                                                <h1>For the Future, we build <br/> customer centric <br/> experiences  backed by <br/> <span>Creativity</span></h1>
                                                <p>Found what you are looking for?</p>
                                            </div>
                                    </Col>
                                    </Row>
                                </Container>
                                </div>
                            </div>
                    </Slider>
                </div>
                <div className="desktop-slide-small">
                    <Slider {...settings} >
                            <div className="banner-inner"  style={{paddingTop: props.heightPass}}>
                                <div className="banner-one-bg">
                                {/* <img src={BannerBg1} alt="Banner Backgroung" className="img-fluid" /> */}
                                <Container>
                                    <Row>
                                        <Col>
                                            <img src={desktopSlideSmall1} alt="banner" className="img-fluid" usemap="#image-map3" />
                                            <map name="image-map3">
                                                <area target="_self" alt="" title="" href="/products/api-datafeed" coords="81,189,107,149,131,191,108,233,62,233,38,191,63,149,107,150" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/wealthwraps" coords="158,143,183,102,209,144,185,185,137,184,113,143,137,105,182,103,180,105" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/ifa-transact" coords="234,102,261,58,285,98,261,140,217,140,192,98,216,57,261,58" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/cmots-ekyc" coords="162,235,184,192,208,233,185,274,140,275,114,232,138,193,184,193" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/ipo-online" coords="239,191,261,146,286,187,263,228,215,229,192,188,214,148,261,146" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/cmots-lms" coords="317,144,338,101,365,143,340,182,293,182,269,142,292,99,338,102" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/sales-crm" coords="238,279,264,236,286,277,265,319,219,320,192,277,217,235,263,236" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/virtual-portfolio-tracker" coords="317,235,341,190,364,231,343,274,294,272,272,231,293,193,341,191" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/issue-tracking" coords="393,187,417,145,441,185,418,226,369,226,346,186,370,144,417,146" shape="poly"></area>
                                            </map>
                                            <div className="banner-content">
                                                <h1>Your Partner in Financial <br/> Growth with a Focus on <br/> <span>Quality</span></h1>
                                                <p>We specialize in transforming data into actionable insights, <br/>helping you navigate the complexities of finance with <br/>confidence</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                                </div>
                            </div>
                            
                            <div className="banner-inner"  style={{paddingTop: props.heightPass}}>
                                <div className="banner-two-bg">
                                {/* <img src={BannerBg1} alt="Banner Backgroung" className="img-fluid" /> */}
                                <Container>
                                    <Row>
                                        <Col>
                                            <img src={desktopSlideSmall2} alt="banner" className="img-fluid"  usemap="#image-map4"/>
                                            <map name="image-map4">
                                                <area target="_self" alt="" title="" href="/services/market-data-provider" coords="315,82,45" shape="circle"></area>
                                                <area target="_self" alt="" title="" href="/services/mobile-development" coords="380,171,46" shape="circle"></area>
                                                <area target="_self" alt="" title="" href="/services/customized-software-development" coords="315,260,45" shape="circle"></area>
                                                <area target="_self" alt="" title="" href="/services/website-design-and-development" coords="166,259,46" shape="circle"></area>
                                                <area target="_self" alt="" title="" href="/services/website-hosting" coords="99,172,45" shape="circle"></area>
                                                <area target="_self" alt="" title="" href="/services/resource-staffing" coords="164,81,46" shape="circle"></area>
                                            </map>
                                            
                                            <div className="banner-content">
                                                <h1>For the Future, we build <br/> customer centric <br/> experiences  backed by <br/> <span>Creativity</span></h1>
                                                <p>Found what you are looking for?</p>
                                            </div>
                                    </Col>
                                    </Row>
                                </Container>
                                </div>
                            </div>
                    </Slider>
                </div>
                <div className="tab-slide">
                    <Slider {...settings} >
                            <div className="banner-inner"  style={{paddingTop: props.heightPass}}>
                                <div className="banner-one-bg">
                                {/* <img src={BannerBg1} alt="Banner Backgroung" className="img-fluid" /> */}
                                <Container>
                                    <Row>
                                        <Col>
                                            <img src={tabSlide1} alt="banner" className="img-fluid" usemap="#image-map5" />
                                            <map name="image-map5">
                                                <area target="_self" alt="" title="" href="/products/api-datafeed" coords="55,130,73,102,88,128,73,157,41,156,25,130,41,102,73,102" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/wealthwraps" coords="109,100,125,71,140,97,124,125,93,125,77,99,93,71,125,71" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/ifa-transact" coords="159,69,177,41,193,68,177,94,145,94,129,67,145,40,177,40" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/cmots-ekyc" coords="107,160,124,131,141,157,125,186,92,186,77,158,93,132,124,131" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/ipo-online" coords="161,129,177,100,193,128,177,154,146,154,129,128,146,100,177,100" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/cmots-lms" coords="212,97,229,69,246,97,230,123,198,123,181,97,196,69,229,69" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/sales-crm" coords="161,188,178,160,194,188,178,214,146,214,130,188,147,161,178,160" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/virtual-portfolio-tracker" coords="216,157,230,130,246,157,230,184,198,184,183,158,199,130,230,130" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/issue-tracking" coords="266,126,282,98,299,125,282,153,251,153,235,126,251,99,282,98" shape="poly"></area>
                                            </map>
                                            <div className="banner-content">
                                                <h1>Your Partner in Financial <br/> Growth with a Focus on <br/> <span>Quality</span></h1>
                                                <p>We specialize in transforming data into actionable insights, <br/>helping you navigate the complexities of finance with <br/>confidence</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                                </div>
                            </div>
                            
                            <div className="banner-inner"  style={{paddingTop: props.heightPass}}>
                                <div className="banner-two-bg">
                                {/* <img src={BannerBg1} alt="Banner Backgroung" className="img-fluid" /> */}
                                <Container>
                                    <Row>
                                        <Col>
                                            <img src={tabSlide2} alt="banner" className="img-fluid"  usemap="#image-map6"/>
                                            <map name="image-map6">
                                                <area target="_self" alt="" title="" href="/services/market-data-provider " coords="213,56,31" shape="circle"></area>
                                                <area target="_self" alt="" title="" href="/services/mobile-development" coords="256,117,31" shape="circle"></area>
                                                <area target="_self" alt="" title="" href="/services/customized-software-development" coords="213,176,30" shape="circle"></area>
                                                <area target="_self" alt="" title="" href="/services/website-design-and-development" coords="112,176,30" shape="circle"></area>
                                                <area target="_self" alt="" title="" href="/services/website-hosting" coords="67,116,30" shape="circle"></area>
                                                <area target="_self" alt="" title="" href="/services/resource-staffing" coords="111,55,31" shape="circle"></area>
                                            </map>
                                            <div className="banner-content">
                                                <h1>For the Future, we build <br/> customer centric <br/> experiences  backed by <br/> <span>Creativity</span></h1>
                                                <p>Found what you are looking for?</p>
                                            </div>
                                    </Col>
                                    </Row>
                                </Container>
                                </div>
                            </div>
                    </Slider>
                </div>
                <div className="mobile-slide-big">
                    <Slider {...settings} >
                            <div className="banner-inner"  style={{paddingTop: props.heightPass}}>
                                <div className="banner-one-bg">
                                {/* <img src={BannerBg1} alt="Banner Backgroung" className="img-fluid" /> */}
                                <Container>
                                    <Row>
                                        <Col>
                                            <img src={mobileSlibeBig1} alt="banner" className="img-fluid" usemap="#image-map7" />
                                            <map name="image-map7">
                                                <area target="_self" alt="" title="" href="/products/api-datafeed" coords="91,204,115,160,140,203,113,245,65,245,39,205,66,160,114,160" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/wealthwraps" coords="171,157,196,112,221,153,196,196,147,197,123,154,148,111,196,111" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/ifa-transact" coords="251,107,279,64,302,104,275,149,229,147,203,106,229,63,279,63" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/cmots-ekyc" coords="171,251,196,207,221,248,196,291,149,291,123,249,147,205,196,206" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/ipo-online" coords="257,201,279,158,304,201,280,243,229,244,205,200,229,157,279,158" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/cmots-lms" coords="334,152,362,108,386,152,360,194,311,195,287,152,312,109,362,110" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/sales-crm" coords="253,297,281,252,304,296,281,339,231,338,207,294,231,251,282,252" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/virtual-portfolio-tracker" coords="337,249,362,204,387,248,363,290,313,290,289,248,313,204,361,204" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/issue-tracking" coords="418,199,444,155,468,199,444,241,396,241,370,198,395,155,444,155" shape="poly"></area>
                                            </map>
                                            <div className="banner-content">
                                                <h1>Your Partner in Financial <br/> Growth with a Focus on <br/> <span>Quality</span></h1>
                                                <p>We specialize in transforming data into actionable insights, <br/>helping you navigate the complexities of finance with <br/>confidence</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                                </div>
                            </div>
                            
                            <div className="banner-inner"  style={{paddingTop: props.heightPass}}>
                                <div className="banner-two-bg">
                                {/* <img src={BannerBg1} alt="Banner Backgroung" className="img-fluid" /> */}
                                <Container>
                                    <Row>
                                        <Col>
                                            <img src={mobileSlibeBig2} alt="banner" className="img-fluid"  usemap="#image-map8"/>
                                            <map name="image-map8">
                                                <area target="_self" alt="" title="" href="/services/market-data-provider" coords="337,88,48" shape="circle"></area>
                                                <area target="_self" alt="" title="" href="/services/mobile-development" coords="402,183,47" shape="circle"></area>
                                                <area target="_self" alt="" title="" href="/services/customized-software-development" coords="335,277,47" shape="circle"></area>
                                                <area target="_self" alt="" title="" href="/services/website-design-and-development" coords="175,277,47" shape="circle"></area>
                                                <area target="_self" alt="" title="" href="/services/website-hosting" coords="106,184,48" shape="circle"></area>
                                                <area target="_self" alt="" title="" href="/services/resource-staffing" coords="175,88,47" shape="circle"></area>
                                            </map>
                                            <div className="banner-content">
                                                <h1>For the Future, we build <br/> customer centric <br/> experiences  backed by <br/> <span>Creativity</span></h1>
                                                <p>Found what you are looking for?</p>
                                            </div>
                                    </Col>
                                    </Row>
                                </Container>
                                </div>
                            </div>
                    </Slider>
                </div>
                <div className="mobile-slide-middle">
                    <Slider {...settings} >
                            <div className="banner-inner"  style={{paddingTop: props.heightPass}}>
                                <div className="banner-one-bg">
                                {/* <img src={BannerBg1} alt="Banner Backgroung" className="img-fluid" /> */}
                                <Container>
                                    <Row>
                                        <Col>
                                            <img src={mobileSlideMiddle1} alt="banner" className="img-fluid" usemap="#image-map9" />
                                            <map name="image-map9">
                                                <area target="_self" alt="" title="" href="/products/api-datafeed" coords="82,182,57,141,101,141,123,180,101,217,57,217,34,179,57,141" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/wealthwraps" coords="151,138,129,98,173,98,196,136,173,174,130,174,108,135,130,98" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/ifa-transact" coords="223,94,201,54,246,54,268,94,245,132,200,131,179,92,201,54" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/cmots-ekyc" coords="151,221,129,182,174,181,195,219,174,258,129,258,107,220,129,183" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/ipo-online" coords="223,178,201,139,246,139,268,178,246,215,203,216,181,178,201,139" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/cmots-lms" coords="299,135,275,95,319,95,341,133,319,172,275,172,253,134,275,95" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/sales-crm" coords="227,261,204,223,249,223,269,262,247,298,203,298,181,260,204,223" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/virtual-portfolio-tracker" coords="299,221,277,180,320,179,342,217,320,258,277,256,255,218,277,180" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/issue-tracking" coords="371,176,348,136,392,136,415,174,392,213,349,212,326,175,349,136" shape="poly"></area>
                                            </map>
                                            <div className="banner-content">
                                                <h1>Your Partner in Financial <br/> Growth with a Focus on <br/> <span>Quality</span></h1>
                                                <p>We specialize in transforming data into actionable insights, <br/>helping you navigate the complexities of finance with <br/>confidence</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                                </div>
                            </div>
                            
                            <div className="banner-inner"  style={{paddingTop: props.heightPass}}>
                                <div className="banner-two-bg">
                                {/* <img src={BannerBg1} alt="Banner Backgroung" className="img-fluid" /> */}
                                <Container>
                                    <Row>
                                        <Col>
                                            <img src={mobileSlideMiddle2} alt="banner" className="img-fluid"  usemap="#image-map10"/>
                                            <map name="image-map10">
                                                <area target="_self" alt="" title="" href="/services/market-data-provider" coords="297,77,43" shape="circle"></area>
                                                <area target="_self" alt="" title="" href="/services/mobile-development" coords="356,163,42" shape="circle"></area>
                                                <area target="_self" alt="" title="" href="/services/customized-software-development" coords="296,245,43" shape="circle"></area>
                                                <area target="_self" alt="" title="" href="/services/website-design-and-development" coords="154,245,43" shape="circle"></area>
                                                <area target="_self" alt="" title="" href="/services/website-hosting" coords="92,162,43" shape="circle"></area>
                                                <area target="_self" alt="" title="" href="/services/resource-staffing" coords="153,77,43" shape="circle"></area>
                                            </map>
                                            <div className="banner-content">
                                                <h1>For the Future, we build <br/> customer centric <br/> experiences  backed by <br/> <span>Creativity</span></h1>
                                                <p>Found what you are looking for?</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                                </div>
                            </div>
                    </Slider>
                </div>
                <div className="mobile-slide-small">
                    <Slider {...settings} >
                            <div className="banner-inner"  style={{paddingTop: props.heightPass}}>
                                <div className="banner-one-bg">
                                {/* <img src={BannerBg1} alt="Banner Backgroung" className="img-fluid" /> */}
                                <Container>
                                    <Row>
                                        <Col>
                                            <img src={mobileSlideSmall1} alt="banner" className="img-fluid" usemap="#image-map11" />
                                            <map name="image-map11">
                                                <area target="_self" alt="" title="" href="/products/api-datafeed" coords="50,116,37,91,65,91,79,114,64,140,36,140,22,115,37,92" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/wealthwraps" coords="98,89,83,63,112,63,126,88,111,112,84,112,69,87,83,63" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/ifa-transact" coords="143,63,130,35,159,36,172,61,156,84,131,84,116,60,130,36" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/cmots-ekyc" coords="97,144,84,118,111,118,125,141,111,165,84,165,70,142,84,118" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/ipo-online" coords="146,115,131,90,159,90,173,114,159,138,131,138,117,115,131,91" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/cmots-lms" coords="192,88,206,62,177,62,163,86,177,110,206,110,220,87,206,63" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/sales-crm" coords="146,169,131,143,159,143,173,168,159,192,132,192,117,168,131,144" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/virtual-portfolio-tracker" coords="193,142,178,116,205,116,220,140,206,165,177,165,164,140,177,117,177,116" shape="poly"></area>
                                                <area target="_self" alt="" title="" href="/products/issue-tracking" coords="239,112,224,88,252,88,266,113,252,137,225,137,210,112,224,89" shape="poly"></area>
                                            </map>
                                            <div className="banner-content">
                                                <h1>Your Partner in Financial <br/> Growth with a Focus on <br/> <span>Quality</span></h1>
                                                <p>We specialize in transforming data into actionable insights, <br/>helping you navigate the complexities of finance with <br/>confidence</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                                </div>
                            </div>
                            
                            <div className="banner-inner"  style={{paddingTop: props.heightPass}}>
                                <div className="banner-two-bg">
                                {/* <img src={BannerBg1} alt="Banner Backgroung" className="img-fluid" /> */}
                                <Container>
                                    <Row>
                                        <Col>
                                            <img src={mobileSlideSmall2} alt="banner" className="img-fluid"  usemap="#image-map12"/>
                                            <map name="image-map12">
                                                <area target="_self" alt="" title="" href="/services/market-data-provider" coords="193,49,28" shape="circle"></area>
                                                <area target="_self" alt="" title="" href="/services/mobile-development" coords="230,104,28" shape="circle"></area>
                                                <area target="_self" alt="" title="" href="/services/customized-software-development" coords="192,157,28" shape="circle"></area>
                                                <area target="_self" alt="" title="" href="/services/website-design-and-development" coords="99,157,28" shape="circle"></area>
                                                <area target="_self" alt="" title="" href="/services/website-hosting" coords="61,105,27" shape="circle"></area>
                                                <area target="_self" alt="" title="" href="/services/resource-staffing" coords="101,49,28" shape="circle"></area>
                                            </map>
                                            <div className="banner-content">
                                                <h1>For the Future, we build <br/> customer centric <br/> experiences  backed by <br/> <span>Creativity</span></h1>
                                                <p>Found what you are looking for?</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                                </div>
                            </div>
                    </Slider>
                </div>
            </div>
        </>
    )
}

export default Banner;