import React, { useEffect, useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";
import "./Header.css";
import cmotslogo from "./../Images/cmots-logo.png";
import mail from "./../Images/mail.png";
import india from "./../Images/india.png";
// import hamburger from "./../Images/hamburger.png";
import MobileNav from "./MobileNav";
import { BiMenuAltRight } from "react-icons/bi";
import service1 from "./../Images/menu-service-1.png";
import service2 from "./../Images/Website-Hosting.png";
import service3 from "./../Images/Mobile-Development.png";
import service4 from "./../Images/Data-Provider.png";
import service5 from "./../Images/SAAS-MBAAS.png";
import service6 from "./../Images/Resource-Staffing.png";
import product1 from "./../Images/API-DataFeed.png";
import product2 from "./../Images/WealthWraps.png";
import product3 from "./../Images/IFATransact.png";
import product4 from "./../Images/CMOTS-eKYC.png";
import product5 from "./../Images/ipo-online.png";
import product6 from "./../Images/CMOTS-LMS.png";
import product7 from "./../Images/Sales-CRM.png";
import product8 from "./../Images/Virtual-Portfolio-Tracker.png";
import product9 from "./../Images/issue-tracking-system.png";
import productoverview from "./../Images/overview-product.png";
import serviceoverview from "./../Images/overview-service.png";



const Header = ({ setDimensions }) => {
    const navigate = useNavigate();
    const setHeadHeightCalc = () => {
        const height = document.querySelector(".header-sc").offsetHeight;
        //console.log('Height', height)
        setDimensions(height);
    }
    //alert({dimensions})
    const [showText, setShowText] = useState(false);
    const [hoverMenu, setHoverMenu] = useState(1);
     const [hoverMenuProduct, setHoverMenuProduct] = useState(1);
    // const clickHanlder = () => {
    //     setShowText(true)
    // }
    useEffect(() => {
        window.addEventListener("scroll", headerBg);
        return () => {
            window.removeEventListener("scroll", headerBg);
        }
    })
    const headerBg = () => {
        const headerSelect = document.querySelector(".header-sc");
        const scrollTop = window.scrollY;
        scrollTop > 100 ? headerSelect.classList.add("change-header-bg") : headerSelect.classList.remove("change-header-bg");
    }
    const handleHoverState = (e) => {
        //console.log("e",e);
        setHoverMenu(e);
    }

    const handleHoverStateP =(e) =>{
        setHoverMenuProduct(e)
    }
    const handleChange = () => {
       
        let connectElement = localStorage.getItem('connect')
        let path = '/';

        navigate(path);

        setTimeout(() => {
            window.scrollTo({
                top: document.getElementById(connectElement).offsetTop - document.getElementsByClassName('header-sc')[0].offsetHeight,
                behavior: 'smooth',
            });
        }, 500);


    }
    useEffect(() => {
        localStorage.setItem("connect", 'connectElement')
    }, [])
    return (
        <>
            {showText ? <MobileNav closeNav={setShowText} /> : null}
            <header className="header-sc" onLoad={setHeadHeightCalc}>
                <Container>
                    <Row>
                        <Col>
                            <div className="header-main">
                                <div className="logo-sc">
                                    <a href="/" ><img src={cmotslogo} alt="logo" className="img-fluid" /></a>
                                </div>
                                <div className="links-header">
                                    <div className="menu-sc">

                                            <Navbar>
                                                <Nav>
                                                <Link className="nav-link"  to="/about-us">About Us</Link>
                                                <li className="nav-link">Services
                                                    <div className="hover-menu">
                                                        <Container>
                                                            <Row>
                                                                <Col>
                                                                    <ul className="inner-header-links ">
                                                                        <li onMouseOver={(e) => handleHoverState(0)} onMouseLeave={(e) => handleHoverState(0)} className={hoverMenu == "0" ? "active" : ""} >
                                                                            <a href="/services">Overview</a>
                                                                            <div className="inner-header-content">
                                                                                <img src={serviceoverview} alt="service" className="img-fluid" />
                                                                                <p>CMOTS addresses your financial and business needs with practical solutions. Our services prioritize efficiency and transparency, providing tailored support for your unique requirements and ensuring success in every aspect.</p>
                                                                                <a href="/services" className="common-blue-btn">View More</a>
                                                                            </div>
                                                                        </li>
                                                                        <li onMouseOver={(e) => handleHoverState(5)} onMouseLeave={(e) => handleHoverState(5)} className={hoverMenu == "5" ? "active" : ""}>
                                                                            <a href="/services/market-data-provider">Market Data Provider</a>
                                                                            <div className="inner-header-content">
                                                                                <img src={service4} alt="service" className="img-fluid" />
                                                                                <p>We are market data providing company for Indian Capital market . Our data is in customised tabulated format as a result our data can be read easily and adapted for any platform.</p>
                                                                                <a href="/services/market-data-provider" className="common-blue-btn">View More</a>
                                                                            </div>
                                                                        </li>
                                                                        <li onMouseOver={(e) => handleHoverState(3)} onMouseLeave={(e) => handleHoverState(3)} className={hoverMenu == "3" ? "active" : ""}>
                                                                            <a href="/services/mobile-development">Mobile Development</a>
                                                                            <div className="inner-header-content">
                                                                                <img src={service3} alt="service" className="img-fluid" />
                                                                                <p>We ensure that our clients experience the benefits of being mobile by providing the best mobile app development service . Right from a mini version of your website  to a customised mobile application , we cater to all business owners with mobile needs. Our mobile application  experts strive to stay ahead of the competition by leveraging their skills to develop cutting-edge mobile applications. </p>
                                                                                <a href="/services/mobile-development" className="common-blue-btn">View More</a>
                                                                            </div>
                                                                        </li>
                                                                        <li onMouseOver={(e) => handleHoverState(4)} onMouseLeave={(e) => handleHoverState(4)} className={hoverMenu == "4" ? "active" : ""}>
                                                                            <a href="/services/customized-software-development">Customized Software Development</a>
                                                                            <div className="inner-header-content">
                                                                                <img src={service5} alt="service" className="img-fluid" />
                                                                                <p>With our managed IT service we help CIOs and CTOs to face the challenge of providing high quality service and integrating the latest technology at minimal costs.</p>
                                                                                <a href="/services/customized-software-development" className="common-blue-btn">View More</a>
                                                                            </div>
                                                                        </li>
                                                                        <li onMouseOver={(e) => handleHoverState(1)} onMouseLeave={(e) => handleHoverState(1)} className={hoverMenu == "1" ? "active" : ""} >
                                                                            <a href="/services/website-design-and-development">Website Design & Development</a>
                                                                            <div className="inner-header-content">
                                                                                <img src={service1} alt="service" className="img-fluid" />
                                                                                <p>Our website design services are customized as per the needs of our clients. We believe in creating professional and engaging web design. The focus is on user-friendly layouts and easy navigation using the latest technology. Websites are developed using core engines such as .NET and PHP.</p>
                                                                                <a href="/services/website-design-and-development" className="common-blue-btn">View More</a>
                                                                            </div>
                                                                        </li>
                                                                        <li onMouseOver={(e) => handleHoverState(2)} onMouseLeave={(e) => handleHoverState(2)} className={hoverMenu == "2" ? "active" : ""}>
                                                                            <a href="/services/website-hosting">Website Hosting</a>
                                                                            <div className="inner-header-content">
                                                                                <img src={service2} alt="service" className="img-fluid" />
                                                                                <p>We provide the fastest link between your content and visitors at the lowest cost, whether you are putting up your first website or you are an experienced Internet money-making expert.</p>
                                                                                <a href="/services/website-hosting" className="common-blue-btn">View More</a>
                                                                            </div>
                                                                        </li>
                                                                        
                                                                      
                                                                      
                                                                       
                                                                        <li onMouseOver={(e) => handleHoverState(6)} onMouseLeave={(e) => handleHoverState(6)} className={hoverMenu == "6" ? "active" : ""}>
                                                                            <a href="/services/resource-staffing">Resource Staffing</a>
                                                                            <div className="inner-header-content">
                                                                                <img src={service6} alt="service" className="img-fluid" />
                                                                                <p>We provide high-caliber IT talent recruitment services.<br />We help you to achieve your goal of gaining greater cost saving, better project control and more consistent processes and outcomes by providing resources that match your requirements.</p>
                                                                                <a href="/services/resource-staffing" className="common-blue-btn">View More</a>
                                                                            </div>
                                                                        </li>

                                                                    </ul>
                                                                </Col>
                                                            </Row>
                                                        </Container>

                                                    </div>
                                                </li>
                                                <li className="nav-link" >Products
                                                    <div className="hover-menu">
                                                        <Container>
                                                            <Row>
                                                                <Col>
                                                                    <ul className="inner-header-links ">
                                                                    <li onMouseOver={(e) => handleHoverStateP(0)} onMouseLeave={(e) => handleHoverStateP(0)} className={hoverMenuProduct == "0" ? "active" : ""} >
                                                                            <a href="/products">Overview</a>
                                                                            <div className="inner-header-content">
                                                                                <img src={productoverview} alt="service" className="img-fluid" />
                                                                                <p>Discover CMOTS' practical and effective product range designed to meet diverse business needs. Our solutions prioritize simplicity, reliability, and customized efficiency, ensuring tangible success for our clients.</p>
                                                                                <a href="/products" className="common-blue-btn">View More</a>
                                                                            </div>
                                                                        </li>
                                                                    <li onMouseOver={(e) => handleHoverStateP(1)} onMouseLeave={(e) => handleHoverStateP(1)} className={hoverMenuProduct == "1"  ? "active" : ""} >
                                                                            <a href="/products/api-datafeed">API DataFeed</a>
                                                                            <div className="inner-header-content">
                                                                                <img src={product1} alt="service" className="img-fluid" />
                                                                                <p>We provide financial market API's, which are in easy to use data formats. Financial APIs for Equity, Mutual Funds, Company Announcements, Commodities, Currency, News, Other Markets, IPOs, Company Information, Derivatives with different frequency options such as Delayed, Historical and End of the Day.</p>
                                                                                <a href="/products/api-datafeed" className="common-blue-btn">View More</a>
                                                                            </div>
                                                                        </li>
                                                                        <li onMouseOver={(e) => handleHoverStateP(2)} onMouseLeave={(e) => handleHoverStateP(2)} className={hoverMenuProduct == "2" ? "active" : ""}>
                                                                             <a href="/products/wealthwraps">WealthWraps</a>
                                                                            <div className="inner-header-content">
                                                                                <img src={product2} alt="service" className="img-fluid" />
                                                                                <p>Wealth Wraps is a one-stop solution for investment management. It has been designed keeping in mind the requirements of an individual investor as well as a portfolio manager. It allows users to track their fixed-income/tenure and non-fixed income/tenure investments.</p>
                                                                                <a href="/products/wealthwraps" className="common-blue-btn">View More</a>
                                                                            </div>
                                                                        </li>
                                                                        <li onMouseOver={(e) => handleHoverStateP(3)} onMouseLeave={(e) => handleHoverStateP(3)} className={hoverMenuProduct == "3" ? "active" : ""}>
                                                                            <a href="/products/ifa-transact">IFA Transact</a>
                                                                            <div className="inner-header-content">
                                                                                <img src={product3} alt="service" className="img-fluid" />
                                                                                <p>IFA Transact will provide you seamless and user friendly experience to manage your customers Mutual Fund portfolio. A Complete mutual fund transaction platform under your brand name with feature loaded to reduce your load.</p>
                                                                                <a href="/products/ifa-transact" className="common-blue-btn">View More</a>
                                                                            </div>
                                                                        </li>
                                                                        <li onMouseOver={(e) => handleHoverStateP(4)} onMouseLeave={(e) => handleHoverStateP(4)} className={hoverMenuProduct == "4" ? "active" : ""}>
                                                                            <a href="/products/cmots-ekyc">CMOTS eKYC</a>
                                                                            <div className="inner-header-content">
                                                                                <img src={product4} alt="service" className="img-fluid" />
                                                                                <p>A Complete KYC processing platform under your brand name with feature loaded to reduce your load. CMOTS eKYC is end to end online solution for users who are fulfilling KYC to open an account for Demat/Trading/Banking/Mutual Fund. User’s can be Resident Individual, non-individual or NRI.</p>
                                                                                <a href="/products/cmots-ekyc" className="common-blue-btn">View More</a>
                                                                            </div>
                                                                        </li>
                                                                        <li onMouseOver={(e) => handleHoverStateP(5)} onMouseLeave={(e) => handleHoverStateP(5)} className={hoverMenuProduct == "5" ? "active" : ""}>
                                                                            <a href="/products/ipo-online">CMOTS IPO Online</a>
                                                                            <div className="inner-header-content">
                                                                                <img src={product5} alt="service" className="img-fluid" />
                                                                                <p>A Complete IPO transaction platform under your brand name with feature loaded to reduce your load. User can now apply for new IPOs (Initial Public Offerings) online . No ASBA (Application Supported by Blocked Amount) , No net banking portal or visiting a bank and submitting a physical application with demat account number is required.</p>
                                                                                <a href="/products/ipo-online" className="common-blue-btn">View More</a>
                                                                            </div>
                                                                        </li>
                                                                        <li onMouseOver={(e) => handleHoverStateP(6)} onMouseLeave={(e) => handleHoverStateP(6)} className={hoverMenuProduct == "6" ? "active" : ""}>
                                                                            <a href="/products/cmots-lms">CMOTS LMS</a>
                                                                            <div className="inner-header-content">
                                                                                <img src={product6} alt="service" className="img-fluid" />
                                                                                <p>CMOTS LMS is systematic process in which incoming leads are managed and tracked accurately, so that your business process becomes hassle free. You can check all your business status in one view using our dashboard.</p>
                                                                                <a href="/products/cmots-lms" className="common-blue-btn">View More</a>
                                                                            </div>
                                                                        </li>
                                                                        <li onMouseOver={(e) => handleHoverStateP(7)} onMouseLeave={(e) => handleHoverStateP(7)} className={hoverMenuProduct == "7" ? "active" : ""}>
                                                                            <a href="/products/sales-crm">Sales CRM</a>
                                                                            <div className="inner-header-content">
                                                                                <img src={product7} alt="service" className="img-fluid" />
                                                                                <p>CMOTS Sales CRM is a great tool to help small businesses deal with the vital task of managing leads, contacts, organizations, partners, vendors and suppliers. Using CRM best practices, you can see everything about a lead.</p>
                                                                                <a href="/products/sales-crm" className="common-blue-btn">View More</a>
                                                                            </div>
                                                                        </li> 
                                                                        <li onMouseOver={(e) => handleHoverStateP(8)} onMouseLeave={(e) => handleHoverStateP(8)} className={hoverMenuProduct == "8" ? "active" : ""}>
                                                                            <a href="/products/virtual-portfolio-tracker">Virtual Portfolio Tracker</a>
                                                                            <div className="inner-header-content">
                                                                                <img src={product8} alt="service" className="img-fluid" />
                                                                                <p>The Portfolio Tracker is a web-based tool that allows users to track their fixed-income / tenure and non-fixed income / tenure investments and to key in details of investment instruments like Bank FDs , Company FDs , Stocks , Mutual Funds Schemes , Bonds, RBI Bonds , GoI Post Office Savings , Insurance , Bullion , Real Estate , F&O.</p>
                                                                                <a href="/products/virtual-portfolio-tracker" className="common-blue-btn">View More</a>
                                                                            </div>
                                                                        </li> 
                                                                        <li onMouseOver={(e) => handleHoverStateP(9)} onMouseLeave={(e) => handleHoverStateP(9)} className={hoverMenuProduct == "9" ? "active" : ""}>
                                                                            <a href="/products/issue-tracking">Issue Tracking system</a>
                                                                            <div className="inner-header-content">
                                                                                <img src={product9} alt="service" className="img-fluid" />
                                                                                <p>CM SupportDesk a practical and robust support ticket System to reduce chaos and bring order to your support process. Bring all requests under one roof together painlessly and improve your support response time.</p>
                                                                                <a href="/products/issue-tracking" className="common-blue-btn">View More</a>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </Col>
                                                            </Row>
                                                        </Container>

                                                    </div>
                                                </li>
                                                <Link className="nav-link"  to="/contact-us">Contact Us</Link>
                                                {/* <Nav.Link href="/Technologies">Technologies</Nav.Link> */}
                                            </Nav>
                                        </Navbar>
                                    </div>
                                    <div>
                                        <div>
                                            <ul className="contact-head">
                                                <li> <a href="mailto:info@cmots.com" > <img src={mail} alt="mail" className="img-fluid" /> <span className="MailTextShowHide mr-1">info@cmots.com </span> </a></li>
                                                <li className="contact-head-link"><img src={india} alt="india" className="img-fluid" /> <span className="ml-1"> Business Enquiry : <a href="tel:+91-9769005437"> +91 9769005437 </a> <h6>  +91 9769005437 </h6> </span></li>
                                            </ul>
                                            <Link to="/careers" className="header-btn mr-2">Join Us</Link>
                                            <a onClick={handleChange} className="header-btn">Let’s Talk</a>
                                            {/* <div className="HumbargerIconDiv" onClick={clickHanlder}>
                                                <BiMenuAltRight className="HumbargerIcon" />
                                            </div> */}
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </header>
        </>
    )
}

export default Header;