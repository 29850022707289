import React from "react";
import "./Footer.css";
import { Col, Container, Row } from "react-bootstrap";
import logo from "./../Images/logo.png";
import Instagram from "./../Images/instagram.png";
import Facebbok from "./../Images/facebook.png";
import Linkdein from "./../Images/linkdein.png";
import Twitter from "./../Images/twitter.png";
import { Link } from "react-router-dom";
const Footer = () =>{
    return(
        <>
            <div className="footer-sc">
                <Container>
                    <Row>
                        <Col>
                            <div>
                                <Link to="/"><img src={logo} alt="logo" className="img-fluid" /></Link>
                            </div>
                            <div className="footer-links">
                                <div className="company-info-box">
                                    <h3>CMOTS Internet Technologies Pvt. Ltd.</h3>
                                    <b> Registered Office</b>
                                    <p>19/20, 'A' Wing, Satyam Shopping Centre, M.G. Road, Ghatkopar(E), Mumbai -  400077, Maharashtra, India.
</p>
                                    {/* <b> Corporate Office </b>
                                    <p>101, Swastik Chambers, Sion - Trombay Road, Chembur(E), Mumbai - 400 071, Maharashtra, India.</p> */}
                                    {/* <div className="info-inner">
                                        <label>Phone</label>
                                        <p>91-22-25229720</p>
                                    </div> */}
                                    <div className="info-inner">
                                        <label>E-mail</label> : <a href="mailto:info@cmots.com">info@cmots.com</a>
                                        
                                    </div>
                                    <div className="info-inner info-inner-Link">
                                        <label>Business Enquiry </label>: <a href="tel:+91-9769005437"> +91 9769005437 </a> 
                                        <h6> +91 9769005437 </h6>                                        
                                    </div>
                                    <div className="social-icons">
                                        <a href="https://www.instagram.com/cmots_internet_technologies/" target="_blank"><img src={Instagram} alt="" className="img-fluid" /></a>
                                        <a href="https://www.linkedin.com/company/cmots-internet-technologies" target="_blank"><img src={Linkdein} alt="" className="img-fluid" /></a>
                                        <a href="https://www.facebook.com/cmotsinternettechnologies/" target="_blank"><img src={Facebbok} alt="" className="img-fluid" /></a>
                                        <a href="https://twitter.com/cmotsinternet" target="_blank"><img src={Twitter} alt="" className="img-fluid" /></a>                                    </div>
                                </div>
                                <div className="footer-links-box middle-footer-box">
                                    <h4>Products</h4>
                                    <ul>
                                       <li><Link to="/products/api-datafeed">API DataFeed</Link></li>
                                        <li><Link to="/products/wealthwraps">WealthWraps</Link></li>
                                        <li><Link to="/products/ifa-transact">IFA Transact</Link></li>
                                        <li><Link to="/products/cmots-ekyc">CMOTS eKYC</Link></li>
                                        <li><Link to="/products/ipo-online">CMOTS IPO Online</Link></li>
                                        <li><Link to="/products/cmots-lms">CMOTS LMS</Link></li>                                        
                                        {/* <li><a href="#">Content Management System</a></li> */}
                                    </ul>
                                    <ul>
                                        <li><Link to="/products/sales-crm">Sales CRM</Link></li>
                                        <li><Link to="/products/virtual-portfolio-tracker">Virtual Portfolio Tracker</Link></li>
                                        <li><Link to="/products/issue-tracking">Issue Tracking system</Link></li>
                                        {/* <li><a href="#">Analyst Tracker</a></li> */}
                                    </ul>
                                </div>
                                <div className="footer-links-box service-link-box">
                                    <h4>Services</h4>
                                    <ul>
                                        <li><Link to="/services/market-data-provider">Market Data Provider</Link></li>
                                        <li><Link to="/services/mobile-development">Mobile Development</Link></li>
                                        <li><Link to="/services/customized-software-development">Customized Software Development</Link></li>
                                        <li><Link to="/services/website-design-and-development">Website Design & Development</Link></li>
                                        <li><Link to="/services/website-hosting">Website Hosting</Link></li>
                                        <li><Link to="/services/resource-staffing">Resource Staffing</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="footer-links-bottom">
                                <div>
                                    <ul>
                                        <li><Link to="/about-us">About Us</Link></li>
                                        <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                        <li><Link to="/disclaimer">Disclaimer</Link></li>
                                        <li><Link to="/feedback">Feedback</Link></li>
                                    </ul>
                                </div>
                                <div>
                                    <p>Copyright © 2024 CMOTS Internet Technologies Pvt. Ltd. All Rights Reserved</p>
                                </div>
                              
                            </div>
                            </Col>
                    </Row>
                    <div className="w-100 HideShowFooterDiv"> &nbsp; </div>
                </Container>
            </div>
        </>
    )
}
export default Footer;